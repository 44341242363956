*{
  padding: 0;
  margin: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#navbar {
  border-bottom: 2px;
  border-style: solid;
  border-image: linear-gradient(to right, #4ecbff, #f291fb) 1;

}

#mobile-navbar {
  border: solid 2px;
  border-image: linear-gradient(to right, #4ecbff, #f291fb) 1;

}

#navbar-dropdown {
  border-top:    1px solid;
  border-right:  1px solid;
  border-bottom: 1px solid;
  border-left:   1px solid;
  border-image: linear-gradient(to right, #4ecbff, #f291fb) 1;

}

nav {
  position: relative;
}

ul {
  list-style-type: none;
  position: relative;
}

li {
  margin: 0 30px;
  position: relative;
}

.effect-4 a:hover{
  background: -webkit-linear-gradient(#4ecbff, #f291fb);
  background-clip: text;
  color: transparent;
}

.effect-4 a:before {
  content: "[";
  left: -5px;
}

.effect-4 a:after {
  content: "]";
  right: -5px;
}

.effect-4 a:before,
.effect-4 a:after {
  position: absolute;
  opacity: 0;
  background: -webkit-linear-gradient(#4ecbff, #f291fb);
  background-clip: text;
  color: transparent;
  top: -1px;
  transition: all 0.5s ease-in-out;
}

.effect-4 a:hover:before,
.effect-4 a:hover:after {
  opacity: 0.7;
  background: -webkit-linear-gradient(#4ecbff, #f291fb);
  background-clip: text;
  color: transparent;
}

.effect-4 a:hover:before {
  left: -10px;
}

.effect-4 a:hover:after {
  right: -10px;
}


.dropdown:hover .dropdown-menu {
  background-color: #21252a;
  display: block;
  border-top:    1px solid;
  border-right:  1px solid;
  border-bottom: 1px solid;
  border-left:   1px solid;
  border-image: linear-gradient(to right, #4ecbff, #f291fb) 1;
}